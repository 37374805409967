import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import useToast from 'apps/commerce/common/checkout/hooks/useToast';
import { getSiteLocale, postRequest } from '../util';

const PayPalButton = ({ offerToken, isRecurring, clientId, merchantId, onSubmit, disable }) => {
  const { setValue, getValues } = useFormContext();
  const { showToast } = useToast();
  const { price } = getValues();

  const handlePaypalRequestError = (err) => {
    if (err && err.includes('CURRENCY')) {
      showToast({ title: t('messages.paypal_invalid_currency'), type: 'danger' });
    } else {
      showToast({ title: t('messages.paypal_default_error'), type: 'danger' });
    }
  };

  const buildPayPalRequestOptions = () => {
    const { coupon, addressRequired, address, collectTaxId, taxId, quantity, offer } = getValues();
    const addressInfoRequired = addressRequired || collectTaxId;

    return {
      ...(offer.checkoutQuantityEnabled && { quantity }),
      ...(coupon && { couponCode: coupon.couponCode }),
      ...(addressInfoRequired && {
        addressInfo: {
          ...(collectTaxId && { businessNumber: taxId }),
          address_line_1: address.addressLine1,
          address_line_2: address.addressLine2,
          addressCity: address.city,
          addressZip: address.zip,
          addressCountry: address.country,
          ...(address.subdivisionRequired && { addressState: address.subdivision }),
        },
      }),
    };
  };

  const createPayPalOrder = async () => {
    const { priceOverrideRequired, priceOverride } = getValues();

    const options = {
      returnOrderId: true,
      ...buildPayPalRequestOptions(),
    };
    if (priceOverrideRequired && priceOverride) {
      options.offerPriceOverride = priceOverride;
    }
    const resp = await postRequest(`/offers/${offerToken}/paypal_payments`, options)
      // Catch any errors when initializing the PayPal order
      .catch((err) => {
        const errorMessage = err.response.data.error;
        handlePaypalRequestError(errorMessage);
      });
    const orderId = resp?.data?.token;
    if (orderId) {
      setValue('paypalOrderId', orderId);
      return orderId;
    }
    return null;
  };

  const createPayPalBillingAgreement = async () => {
    const options = buildPayPalRequestOptions();
    const resp = await postRequest(
      `/offers/${offerToken}/paypal_payments/billing_agreement`,
      options,
    )
      // Catch any errors when initializing the PayPal order
      .catch((err) => {
        const errorMessage = err.response.data.error;
        handlePaypalRequestError(errorMessage);
      });
    const orderId = resp?.data?.token;
    if (orderId) {
      setValue('paypalOrderId', orderId);
      return orderId;
    }
    return null;
  };

  const createPayPalSubscription = async () => {
    const options = buildPayPalRequestOptions();
    const resp = await postRequest(
      `/offers/${offerToken}/paypal_payments/subscription`,
      options,
    ).catch((err) => {
      const errorMessage = err.response.data.error;
      handlePaypalRequestError(errorMessage);
    });
    const subscriptionId = resp?.data?.id;
    if (subscriptionId) {
      setValue('paypalOrderId', subscriptionId);
      return subscriptionId;
    }
    return null;
  };

  const options = {
    'client-id': clientId,
    'currency': price.currency.toUpperCase(),
    'locale': getSiteLocale(),
  };
  if (merchantId) {
    options['merchant-id'] = merchantId;
  }
  if (isRecurring) {
    options.intent = 'subscription';
    options.vault = true;
  }

  return (
    <span data-testid="paypal-button" className="sage-btn--full-width">
      <PayPalScriptProvider options={options}>
        <PayPalButtons
          fundingSource="paypal" // don't show debit/credit card button
          style={{
            tagline: false, // don't show "Powered by PayPal"
          }}
          createOrder={isRecurring ? undefined : createPayPalOrder}
          createSubscription={isRecurring ? createPayPalSubscription : undefined}
          onApprove={(data) => {
            setValue('paypalPayerId', data.payerID);
            onSubmit();
          }}
          onCancel={() => {
            setValue('paypalOrderId', null);
            setValue('paypalPayerId', null);
          }}
          onError={(e) => {
            // we are handling the error in createPayPalOrder so just throw it here on error
            // to prevent the default error message from showing up in the console
            setValue('paypalOrderId', null);
            setValue('paypalPayerId', null);
            throw new Error(e);
          }}
          disabled={disable}
        />
      </PayPalScriptProvider>
    </span>
  );
};
PayPalButton.propTypes = {
  offerToken: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  merchantId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};

PayPalButton.defaultProps = {
  isRecurring: false,
  merchantId: null,
};
export default PayPalButton;
